import { inject, InjectionKey } from 'vue';
import { TransactionApi } from '../../client';

export const clientKey: InjectionKey<TransactionApi> = Symbol('transaction-api-client');

export function useClient() {
  const router = inject<TransactionApi>(clientKey);
  if (!router) {
    throw new Error('client key not provided');
  }

  return router;
}
