/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Request to create order
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
  /**
   *
   * @type {string}
   * @memberof CreateOrderRequest
   */
  offerId: string;
}

/**
 * Check if a given object implements the CreateOrderRequest interface.
 */
export function instanceOfCreateOrderRequest(value: object): value is CreateOrderRequest {
  if (!('offerId' in value) || value['offerId'] === undefined) return false;
  return true;
}

export function CreateOrderRequestFromJSON(json: any): CreateOrderRequest {
  return CreateOrderRequestFromJSONTyped(json, false);
}

export function CreateOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderRequest {
  if (json == null) {
    return json;
  }
  return {
    offerId: json['offerId'],
  };
}

export function CreateOrderRequestToJSON(json: any): CreateOrderRequest {
  return CreateOrderRequestToJSONTyped(json, false);
}

export function CreateOrderRequestToJSONTyped(
  value?: CreateOrderRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    offerId: value['offerId'],
  };
}
