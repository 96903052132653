/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionCryptoLiquidationProcessStatus } from './TransactionCryptoLiquidationProcessStatus';
import {
  TransactionCryptoLiquidationProcessStatusFromJSON,
  TransactionCryptoLiquidationProcessStatusFromJSONTyped,
  TransactionCryptoLiquidationProcessStatusToJSON,
  TransactionCryptoLiquidationProcessStatusToJSONTyped,
} from './TransactionCryptoLiquidationProcessStatus';

/**
 *
 * @export
 * @interface TransactionCryptoLiquidationProcess
 */
export interface TransactionCryptoLiquidationProcess {
  /**
   *
   * @type {TransactionCryptoLiquidationProcessStatus}
   * @memberof TransactionCryptoLiquidationProcess
   */
  status: TransactionCryptoLiquidationProcessStatus;
  /**
   *
   * @type {string}
   * @memberof TransactionCryptoLiquidationProcess
   */
  cryptoLiquidationId?: string;
}

/**
 * Check if a given object implements the TransactionCryptoLiquidationProcess interface.
 */
export function instanceOfTransactionCryptoLiquidationProcess(
  value: object,
): value is TransactionCryptoLiquidationProcess {
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function TransactionCryptoLiquidationProcessFromJSON(json: any): TransactionCryptoLiquidationProcess {
  return TransactionCryptoLiquidationProcessFromJSONTyped(json, false);
}

export function TransactionCryptoLiquidationProcessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionCryptoLiquidationProcess {
  if (json == null) {
    return json;
  }
  return {
    status: TransactionCryptoLiquidationProcessStatusFromJSON(json['status']),
    cryptoLiquidationId: json['cryptoLiquidationId'] == null ? undefined : json['cryptoLiquidationId'],
  };
}

export function TransactionCryptoLiquidationProcessToJSON(json: any): TransactionCryptoLiquidationProcess {
  return TransactionCryptoLiquidationProcessToJSONTyped(json, false);
}

export function TransactionCryptoLiquidationProcessToJSONTyped(
  value?: TransactionCryptoLiquidationProcess | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    status: TransactionCryptoLiquidationProcessStatusToJSON(value['status']),
    cryptoLiquidationId: value['cryptoLiquidationId'],
  };
}
