/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentInstrumentRecollectionOtpConfirmationResponse
 */
export interface PaymentInstrumentRecollectionOtpConfirmationResponse {
  /**
   * Token to include in payment instrument form request
   * @type {string}
   * @memberof PaymentInstrumentRecollectionOtpConfirmationResponse
   */
  verificationToken?: string;
}

/**
 * Check if a given object implements the PaymentInstrumentRecollectionOtpConfirmationResponse interface.
 */
export function instanceOfPaymentInstrumentRecollectionOtpConfirmationResponse(
  value: object,
): value is PaymentInstrumentRecollectionOtpConfirmationResponse {
  return true;
}

export function PaymentInstrumentRecollectionOtpConfirmationResponseFromJSON(
  json: any,
): PaymentInstrumentRecollectionOtpConfirmationResponse {
  return PaymentInstrumentRecollectionOtpConfirmationResponseFromJSONTyped(json, false);
}

export function PaymentInstrumentRecollectionOtpConfirmationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentInstrumentRecollectionOtpConfirmationResponse {
  if (json == null) {
    return json;
  }
  return {
    verificationToken: json['verificationToken'] == null ? undefined : json['verificationToken'],
  };
}

export function PaymentInstrumentRecollectionOtpConfirmationResponseToJSON(
  json: any,
): PaymentInstrumentRecollectionOtpConfirmationResponse {
  return PaymentInstrumentRecollectionOtpConfirmationResponseToJSONTyped(json, false);
}

export function PaymentInstrumentRecollectionOtpConfirmationResponseToJSONTyped(
  value?: PaymentInstrumentRecollectionOtpConfirmationResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    verificationToken: value['verificationToken'],
  };
}
