/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TransactionTrafficProviderConfig
 */
export interface TransactionTrafficProviderConfig {
  /**
   * Traffic provider brand color(s) to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  brand10?: string;
  /**
   * Traffic provider brand color(s) to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  brand110?: string;
  /**
   * Traffic provider brand color(s) to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  brand120?: string;
  /**
   * Traffic provider brand color(s) to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  brand150?: string;
  /**
   * Traffic provider brand color(s) to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  brand1100?: string;
  /**
   * Traffic provider brand color(s) to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  brand1110?: string;
  /**
   * Traffic provider brand color(s) to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  brand1120?: string;
  /**
   * Traffic provider illustration color to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  illustrationsbrand1?: string;
  /**
   * Traffic provider rounded corner radius (percentage of element height) for buttons to override, possible values (0;50]
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  corner50?: string;
  /**
   * Traffic provider shadow color 1 selected to override
   * @type {string}
   * @memberof TransactionTrafficProviderConfig
   */
  shadow1selected?: string;
}

/**
 * Check if a given object implements the TransactionTrafficProviderConfig interface.
 */
export function instanceOfTransactionTrafficProviderConfig(value: object): value is TransactionTrafficProviderConfig {
  return true;
}

export function TransactionTrafficProviderConfigFromJSON(json: any): TransactionTrafficProviderConfig {
  return TransactionTrafficProviderConfigFromJSONTyped(json, false);
}

export function TransactionTrafficProviderConfigFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionTrafficProviderConfig {
  if (json == null) {
    return json;
  }
  return {
    brand10: json['brand10'] == null ? undefined : json['brand10'],
    brand110: json['brand110'] == null ? undefined : json['brand110'],
    brand120: json['brand120'] == null ? undefined : json['brand120'],
    brand150: json['brand150'] == null ? undefined : json['brand150'],
    brand1100: json['brand1100'] == null ? undefined : json['brand1100'],
    brand1110: json['brand1110'] == null ? undefined : json['brand1110'],
    brand1120: json['brand1120'] == null ? undefined : json['brand1120'],
    illustrationsbrand1: json['illustrationsbrand1'] == null ? undefined : json['illustrationsbrand1'],
    corner50: json['corner50'] == null ? undefined : json['corner50'],
    shadow1selected: json['shadow1selected'] == null ? undefined : json['shadow1selected'],
  };
}

export function TransactionTrafficProviderConfigToJSON(json: any): TransactionTrafficProviderConfig {
  return TransactionTrafficProviderConfigToJSONTyped(json, false);
}

export function TransactionTrafficProviderConfigToJSONTyped(
  value?: TransactionTrafficProviderConfig | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    brand10: value['brand10'],
    brand110: value['brand110'],
    brand120: value['brand120'],
    brand150: value['brand150'],
    brand1100: value['brand1100'],
    brand1110: value['brand1110'],
    brand1120: value['brand1120'],
    illustrationsbrand1: value['illustrationsbrand1'],
    corner50: value['corner50'],
    shadow1selected: value['shadow1selected'],
  };
}
