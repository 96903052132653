/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import {
  CryptoMoneyFromJSON,
  CryptoMoneyFromJSONTyped,
  CryptoMoneyToJSON,
  CryptoMoneyToJSONTyped,
} from './CryptoMoney';

/**
 *
 * @export
 * @interface TransactionCryptoTransferInstructions
 */
export interface TransactionCryptoTransferInstructions {
  /**
   *
   * @type {CryptoMoney}
   * @memberof TransactionCryptoTransferInstructions
   */
  crypto: CryptoMoney;
  /**
   * A string representing a crypto wallet address.
   * @type {string}
   * @memberof TransactionCryptoTransferInstructions
   */
  destination: string;
}

/**
 * Check if a given object implements the TransactionCryptoTransferInstructions interface.
 */
export function instanceOfTransactionCryptoTransferInstructions(
  value: object,
): value is TransactionCryptoTransferInstructions {
  if (!('crypto' in value) || value['crypto'] === undefined) return false;
  if (!('destination' in value) || value['destination'] === undefined) return false;
  return true;
}

export function TransactionCryptoTransferInstructionsFromJSON(json: any): TransactionCryptoTransferInstructions {
  return TransactionCryptoTransferInstructionsFromJSONTyped(json, false);
}

export function TransactionCryptoTransferInstructionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionCryptoTransferInstructions {
  if (json == null) {
    return json;
  }
  return {
    crypto: CryptoMoneyFromJSON(json['crypto']),
    destination: json['destination'],
  };
}

export function TransactionCryptoTransferInstructionsToJSON(json: any): TransactionCryptoTransferInstructions {
  return TransactionCryptoTransferInstructionsToJSONTyped(json, false);
}

export function TransactionCryptoTransferInstructionsToJSONTyped(
  value?: TransactionCryptoTransferInstructions | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    crypto: CryptoMoneyToJSON(value['crypto']),
    destination: value['destination'],
  };
}
