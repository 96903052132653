/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CurrenciesFiatCurrenciesInner
 */
export interface CurrenciesFiatCurrenciesInner {
  /**
   * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
   * @type {string}
   * @memberof CurrenciesFiatCurrenciesInner
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CurrenciesFiatCurrenciesInner
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CurrenciesFiatCurrenciesInner
   */
  decimals: number;
  /**
   *
   * @type {string}
   * @memberof CurrenciesFiatCurrenciesInner
   */
  imageUrl: string;
  /**
   *
   * @type {number}
   * @memberof CurrenciesFiatCurrenciesInner
   */
  minAmount?: number;
  /**
   *
   * @type {number}
   * @memberof CurrenciesFiatCurrenciesInner
   */
  maxAmount?: number;
}

/**
 * Check if a given object implements the CurrenciesFiatCurrenciesInner interface.
 */
export function instanceOfCurrenciesFiatCurrenciesInner(value: object): value is CurrenciesFiatCurrenciesInner {
  if (!('code' in value) || value['code'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('decimals' in value) || value['decimals'] === undefined) return false;
  if (!('imageUrl' in value) || value['imageUrl'] === undefined) return false;
  return true;
}

export function CurrenciesFiatCurrenciesInnerFromJSON(json: any): CurrenciesFiatCurrenciesInner {
  return CurrenciesFiatCurrenciesInnerFromJSONTyped(json, false);
}

export function CurrenciesFiatCurrenciesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CurrenciesFiatCurrenciesInner {
  if (json == null) {
    return json;
  }
  return {
    code: json['code'],
    name: json['name'],
    decimals: json['decimals'],
    imageUrl: json['imageUrl'],
    minAmount: json['minAmount'] == null ? undefined : json['minAmount'],
    maxAmount: json['maxAmount'] == null ? undefined : json['maxAmount'],
  };
}

export function CurrenciesFiatCurrenciesInnerToJSON(json: any): CurrenciesFiatCurrenciesInner {
  return CurrenciesFiatCurrenciesInnerToJSONTyped(json, false);
}

export function CurrenciesFiatCurrenciesInnerToJSONTyped(
  value?: CurrenciesFiatCurrenciesInner | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    code: value['code'],
    name: value['name'],
    decimals: value['decimals'],
    imageUrl: value['imageUrl'],
    minAmount: value['minAmount'],
    maxAmount: value['maxAmount'],
  };
}
