/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * indicates the status of fiat payout.
 *
 * - `INITIATED` - payout is created & being executed. waiting to be processed
 * - `FINISHED` - payout finished
 *
 * @export
 */
export const FiatPayoutStatus = {
  Created: 'CREATED',
  Initiated: 'INITIATED',
  Finished: 'FINISHED',
} as const;
export type FiatPayoutStatus = (typeof FiatPayoutStatus)[keyof typeof FiatPayoutStatus];

export function instanceOfFiatPayoutStatus(value: any): boolean {
  for (const key in FiatPayoutStatus) {
    if (Object.prototype.hasOwnProperty.call(FiatPayoutStatus, key)) {
      if (FiatPayoutStatus[key as keyof typeof FiatPayoutStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function FiatPayoutStatusFromJSON(json: any): FiatPayoutStatus {
  return FiatPayoutStatusFromJSONTyped(json, false);
}

export function FiatPayoutStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiatPayoutStatus {
  return json as FiatPayoutStatus;
}

export function FiatPayoutStatusToJSON(value?: FiatPayoutStatus | null): any {
  return value as any;
}

export function FiatPayoutStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): FiatPayoutStatus {
  return value as FiatPayoutStatus;
}
