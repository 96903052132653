/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LpKycVerificationStatus } from './LpKycVerificationStatus';
import {
  LpKycVerificationStatusFromJSON,
  LpKycVerificationStatusFromJSONTyped,
  LpKycVerificationStatusToJSON,
  LpKycVerificationStatusToJSONTyped,
} from './LpKycVerificationStatus';

/**
 *
 * @export
 * @interface TransactionLpKycVerification
 */
export interface TransactionLpKycVerification {
  /**
   *
   * @type {string}
   * @memberof TransactionLpKycVerification
   */
  verificationId: string;
  /**
   *
   * @type {LpKycVerificationStatus}
   * @memberof TransactionLpKycVerification
   */
  status: LpKycVerificationStatus;
}

/**
 * Check if a given object implements the TransactionLpKycVerification interface.
 */
export function instanceOfTransactionLpKycVerification(value: object): value is TransactionLpKycVerification {
  if (!('verificationId' in value) || value['verificationId'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function TransactionLpKycVerificationFromJSON(json: any): TransactionLpKycVerification {
  return TransactionLpKycVerificationFromJSONTyped(json, false);
}

export function TransactionLpKycVerificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionLpKycVerification {
  if (json == null) {
    return json;
  }
  return {
    verificationId: json['verificationId'],
    status: LpKycVerificationStatusFromJSON(json['status']),
  };
}

export function TransactionLpKycVerificationToJSON(json: any): TransactionLpKycVerification {
  return TransactionLpKycVerificationToJSONTyped(json, false);
}

export function TransactionLpKycVerificationToJSONTyped(
  value?: TransactionLpKycVerification | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    verificationId: value['verificationId'],
    status: LpKycVerificationStatusToJSON(value['status']),
  };
}
