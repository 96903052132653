import { createI18n, type I18nOptions } from 'vue-i18n';

/**
 * @deprecated
 **/
export function translationConfig<Messages extends I18nOptions['messages']>(messages: Messages) {
  return createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    availableLocales: { en: 'English' },
    messages,
  });
}
