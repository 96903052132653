/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const KycVerificationStatus = {
  InProgress: 'in_progress',
  Completed: 'completed',
  ApplicantRejected: 'applicant_rejected',
  DocumentRejected: 'document_rejected',
  TimedOut: 'timed_out',
  NotApproved: 'not_approved',
} as const;
export type KycVerificationStatus = (typeof KycVerificationStatus)[keyof typeof KycVerificationStatus];

export function instanceOfKycVerificationStatus(value: any): boolean {
  for (const key in KycVerificationStatus) {
    if (Object.prototype.hasOwnProperty.call(KycVerificationStatus, key)) {
      if (KycVerificationStatus[key as keyof typeof KycVerificationStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function KycVerificationStatusFromJSON(json: any): KycVerificationStatus {
  return KycVerificationStatusFromJSONTyped(json, false);
}

export function KycVerificationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycVerificationStatus {
  return json as KycVerificationStatus;
}

export function KycVerificationStatusToJSON(value?: KycVerificationStatus | null): any {
  return value as any;
}

export function KycVerificationStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): KycVerificationStatus {
  return value as KycVerificationStatus;
}
