/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  ConfirmEmailVerificationAttemptRequest,
  CreateCardPaymentInstrumentCollectionRequest,
  CreateEmailVerificationAttemptRequest,
  CreateEmailVerificationRequest,
  CreateOrderRequest,
  CreateSepaPaymentInstrumentRequest,
  CreateTransactionRequest,
  CryptoLiquidationAgreementResponse,
  CryptoTransfer,
  CryptoTransferDestinationAddress,
  Currencies,
  EmailVerification,
  EmailVerificationAttempt,
  FiatPayout,
  GetTransferInstructions200Response,
  IbanDetails,
  InitiateSellResponse,
  KycCollectionResponse,
  KycInquiryResponse,
  PaymentInstrumentRecollectionOtpConfirmation,
  PaymentInstrumentRecollectionOtpConfirmationResponse,
  PaymentInstrumentRecollectionOtpVerificationResponse,
  PaymentInstrumentResponse,
  ProblemDetails,
  SepaPaymentInstrumentResponse,
  TrafficProviderId,
  Transaction,
  TransactionOffers,
  TransactionOrderResponse,
} from '../models/index';
import {
  ConfirmEmailVerificationAttemptRequestFromJSON,
  ConfirmEmailVerificationAttemptRequestToJSON,
  CreateCardPaymentInstrumentCollectionRequestFromJSON,
  CreateCardPaymentInstrumentCollectionRequestToJSON,
  CreateEmailVerificationAttemptRequestFromJSON,
  CreateEmailVerificationAttemptRequestToJSON,
  CreateEmailVerificationRequestFromJSON,
  CreateEmailVerificationRequestToJSON,
  CreateOrderRequestFromJSON,
  CreateOrderRequestToJSON,
  CreateSepaPaymentInstrumentRequestFromJSON,
  CreateSepaPaymentInstrumentRequestToJSON,
  CreateTransactionRequestFromJSON,
  CreateTransactionRequestToJSON,
  CryptoLiquidationAgreementResponseFromJSON,
  CryptoLiquidationAgreementResponseToJSON,
  CryptoTransferFromJSON,
  CryptoTransferToJSON,
  CryptoTransferDestinationAddressFromJSON,
  CryptoTransferDestinationAddressToJSON,
  CurrenciesFromJSON,
  CurrenciesToJSON,
  EmailVerificationFromJSON,
  EmailVerificationToJSON,
  EmailVerificationAttemptFromJSON,
  EmailVerificationAttemptToJSON,
  FiatPayoutFromJSON,
  FiatPayoutToJSON,
  GetTransferInstructions200ResponseFromJSON,
  GetTransferInstructions200ResponseToJSON,
  IbanDetailsFromJSON,
  IbanDetailsToJSON,
  InitiateSellResponseFromJSON,
  InitiateSellResponseToJSON,
  KycCollectionResponseFromJSON,
  KycCollectionResponseToJSON,
  KycInquiryResponseFromJSON,
  KycInquiryResponseToJSON,
  PaymentInstrumentRecollectionOtpConfirmationFromJSON,
  PaymentInstrumentRecollectionOtpConfirmationToJSON,
  PaymentInstrumentRecollectionOtpConfirmationResponseFromJSON,
  PaymentInstrumentRecollectionOtpConfirmationResponseToJSON,
  PaymentInstrumentRecollectionOtpVerificationResponseFromJSON,
  PaymentInstrumentRecollectionOtpVerificationResponseToJSON,
  PaymentInstrumentResponseFromJSON,
  PaymentInstrumentResponseToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  SepaPaymentInstrumentResponseFromJSON,
  SepaPaymentInstrumentResponseToJSON,
  TrafficProviderIdFromJSON,
  TrafficProviderIdToJSON,
  TransactionFromJSON,
  TransactionToJSON,
  TransactionOffersFromJSON,
  TransactionOffersToJSON,
  TransactionOrderResponseFromJSON,
  TransactionOrderResponseToJSON,
} from '../models/index';

export interface AcceptAgreementRequest {
  cryptoLiquidationId: string;
}

export interface CompleteKycCollectionRequest {
  tid: string;
}

export interface ConfirmEmailVerificationAttemptOperationRequest {
  emailVerificationId: string;
  emailVerificationAttemptId: string;
  confirmEmailVerificationAttemptRequest: ConfirmEmailVerificationAttemptRequest;
}

export interface ConfirmPaymentInstrumentRecollectionOtpVerificationRequest {
  tid: string;
  id: string;
  paymentInstrumentRecollectionOtpConfirmation: PaymentInstrumentRecollectionOtpConfirmation;
}

export interface CreateCardPaymentInstrumentRequest {
  createCardPaymentInstrumentCollectionRequest: CreateCardPaymentInstrumentCollectionRequest;
}

export interface CreateCryptoTransferRequest {
  tid: string;
}

export interface CreateCryptoTransferConsentRequest {
  cryptoTransferId: string;
}

export interface CreateEmailVerificationOperationRequest {
  tid: string;
  createEmailVerificationRequest: CreateEmailVerificationRequest;
}

export interface CreateEmailVerificationAttemptOperationRequest {
  emailVerificationId: string;
  createEmailVerificationAttemptRequest: CreateEmailVerificationAttemptRequest;
}

export interface CreateKycInquiryRequest {
  tid: string;
}

export interface CreateOrderOperationRequest {
  tid: string;
  createOrderRequest: CreateOrderRequest;
}

export interface CreatePaymentInstrumentRecollectionOtpVerificationRequest {
  tid: string;
  id: string;
  body?: object | null;
}

export interface CreateSepaPaymentInstrumentOperationRequest {
  createSepaPaymentInstrumentRequest: CreateSepaPaymentInstrumentRequest;
}

export interface GetCryptoTransferRequest {
  cryptoTransferId: string;
}

export interface GetCryptoTransferDestinationAddressRequest {
  cryptoTransferId: string;
}

export interface GetIbanRequest {
  iban: string;
}

export interface GetPayoutRequest {
  fiatPayoutId: string;
}

export interface GetTransactionRequest {
  tid: string;
}

export interface GetTransactionAvailableOffersRequest {
  tid: string;
  xCountryCode?: string;
}

export interface GetTransactionOrderRequest {
  tid: string;
}

export interface GetTransferInstructionsRequest {
  tid: string;
}

export interface InitiateSellRequest {
  xTrafficProviderId: TrafficProviderId;
  createTransactionRequest: CreateTransactionRequest;
}

export interface RejectAgreementRequest {
  cryptoLiquidationId: string;
}

/**
 *
 */
export class TransactionApi extends runtime.BaseAPI {
  /**
   * Accepts crypto liquidation agreement
   * Accepts crypto liquidation agreement
   */
  async acceptAgreementRaw(
    requestParameters: AcceptAgreementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoLiquidationAgreementResponse>> {
    if (requestParameters['cryptoLiquidationId'] == null) {
      throw new runtime.RequiredError(
        'cryptoLiquidationId',
        'Required parameter "cryptoLiquidationId" was null or undefined when calling acceptAgreement().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/crypto-liquidations/{cryptoLiquidationId}/agreements/confirmations`.replace(
          `{${'cryptoLiquidationId'}}`,
          encodeURIComponent(String(requestParameters['cryptoLiquidationId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoLiquidationAgreementResponseFromJSON(jsonValue));
  }

  /**
   * Accepts crypto liquidation agreement
   * Accepts crypto liquidation agreement
   */
  async acceptAgreement(
    requestParameters: AcceptAgreementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoLiquidationAgreementResponse> {
    const response = await this.acceptAgreementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Marks kyc collection as completed
   * Marks kyc collection as completed
   */
  async completeKycCollectionRaw(
    requestParameters: CompleteKycCollectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<KycCollectionResponse>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling completeKycCollection().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/kyc-collections/completed`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => KycCollectionResponseFromJSON(jsonValue));
  }

  /**
   * Marks kyc collection as completed
   * Marks kyc collection as completed
   */
  async completeKycCollection(
    requestParameters: CompleteKycCollectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<KycCollectionResponse> {
    const response = await this.completeKycCollectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Tries to confirm email address by providing OTP code.
   */
  async confirmEmailVerificationAttemptRaw(
    requestParameters: ConfirmEmailVerificationAttemptOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['emailVerificationId'] == null) {
      throw new runtime.RequiredError(
        'emailVerificationId',
        'Required parameter "emailVerificationId" was null or undefined when calling confirmEmailVerificationAttempt().',
      );
    }

    if (requestParameters['emailVerificationAttemptId'] == null) {
      throw new runtime.RequiredError(
        'emailVerificationAttemptId',
        'Required parameter "emailVerificationAttemptId" was null or undefined when calling confirmEmailVerificationAttempt().',
      );
    }

    if (requestParameters['confirmEmailVerificationAttemptRequest'] == null) {
      throw new runtime.RequiredError(
        'confirmEmailVerificationAttemptRequest',
        'Required parameter "confirmEmailVerificationAttemptRequest" was null or undefined when calling confirmEmailVerificationAttempt().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/email-verifications/{emailVerificationId}/attempts/{emailVerificationAttemptId}/confirmation`
          .replace(`{${'emailVerificationId'}}`, encodeURIComponent(String(requestParameters['emailVerificationId'])))
          .replace(
            `{${'emailVerificationAttemptId'}}`,
            encodeURIComponent(String(requestParameters['emailVerificationAttemptId'])),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ConfirmEmailVerificationAttemptRequestToJSON(requestParameters['confirmEmailVerificationAttemptRequest']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Tries to confirm email address by providing OTP code.
   */
  async confirmEmailVerificationAttempt(
    requestParameters: ConfirmEmailVerificationAttemptOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.confirmEmailVerificationAttemptRaw(requestParameters, initOverrides);
  }

  /**
   * tries to confirm the payment instrument recollection with OTP code
   */
  async confirmPaymentInstrumentRecollectionOtpVerificationRaw(
    requestParameters: ConfirmPaymentInstrumentRecollectionOtpVerificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentInstrumentRecollectionOtpConfirmationResponse>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling confirmPaymentInstrumentRecollectionOtpVerification().',
      );
    }

    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling confirmPaymentInstrumentRecollectionOtpVerification().',
      );
    }

    if (requestParameters['paymentInstrumentRecollectionOtpConfirmation'] == null) {
      throw new runtime.RequiredError(
        'paymentInstrumentRecollectionOtpConfirmation',
        'Required parameter "paymentInstrumentRecollectionOtpConfirmation" was null or undefined when calling confirmPaymentInstrumentRecollectionOtpVerification().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/payment-instrument-recollections/{id}/confirmation`
          .replace(`{${'tid'}}`, encodeURIComponent(String(requestParameters['tid'])))
          .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PaymentInstrumentRecollectionOtpConfirmationToJSON(
          requestParameters['paymentInstrumentRecollectionOtpConfirmation'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentInstrumentRecollectionOtpConfirmationResponseFromJSON(jsonValue),
    );
  }

  /**
   * tries to confirm the payment instrument recollection with OTP code
   */
  async confirmPaymentInstrumentRecollectionOtpVerification(
    requestParameters: ConfirmPaymentInstrumentRecollectionOtpVerificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentInstrumentRecollectionOtpConfirmationResponse> {
    const response = await this.confirmPaymentInstrumentRecollectionOtpVerificationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Creates new card payment instrument
   */
  async createCardPaymentInstrumentRaw(
    requestParameters: CreateCardPaymentInstrumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentInstrumentResponse>> {
    if (requestParameters['createCardPaymentInstrumentCollectionRequest'] == null) {
      throw new runtime.RequiredError(
        'createCardPaymentInstrumentCollectionRequest',
        'Required parameter "createCardPaymentInstrumentCollectionRequest" was null or undefined when calling createCardPaymentInstrument().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/card-payment-instruments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateCardPaymentInstrumentCollectionRequestToJSON(
          requestParameters['createCardPaymentInstrumentCollectionRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaymentInstrumentResponseFromJSON(jsonValue));
  }

  /**
   * Creates new card payment instrument
   */
  async createCardPaymentInstrument(
    requestParameters: CreateCardPaymentInstrumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentInstrumentResponse> {
    const response = await this.createCardPaymentInstrumentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates a crypto transfer object in the system to track status of crypto movement for transaction
   * Creates a crypto transfer for a particular tid
   */
  async createCryptoTransferRaw(
    requestParameters: CreateCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransfer>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling createCryptoTransfer().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/crypto-transfers`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferFromJSON(jsonValue));
  }

  /**
   * Creates a crypto transfer object in the system to track status of crypto movement for transaction
   * Creates a crypto transfer for a particular tid
   */
  async createCryptoTransfer(
    requestParameters: CreateCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransfer> {
    const response = await this.createCryptoTransferRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Records user consent about crypto transfer terms and conditions
   */
  async createCryptoTransferConsentRaw(
    requestParameters: CreateCryptoTransferConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['cryptoTransferId'] == null) {
      throw new runtime.RequiredError(
        'cryptoTransferId',
        'Required parameter "cryptoTransferId" was null or undefined when calling createCryptoTransferConsent().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/crypto-transfers/{cryptoTransferId}/consents`.replace(
          `{${'cryptoTransferId'}}`,
          encodeURIComponent(String(requestParameters['cryptoTransferId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Records user consent about crypto transfer terms and conditions
   */
  async createCryptoTransferConsent(
    requestParameters: CreateCryptoTransferConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createCryptoTransferConsentRaw(requestParameters, initOverrides);
  }

  /**
   * Sends email verification to the visitor
   * Send email verification email to a specified email address
   */
  async createEmailVerificationRaw(
    requestParameters: CreateEmailVerificationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EmailVerification>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling createEmailVerification().',
      );
    }

    if (requestParameters['createEmailVerificationRequest'] == null) {
      throw new runtime.RequiredError(
        'createEmailVerificationRequest',
        'Required parameter "createEmailVerificationRequest" was null or undefined when calling createEmailVerification().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/email-verifications`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateEmailVerificationRequestToJSON(requestParameters['createEmailVerificationRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EmailVerificationFromJSON(jsonValue));
  }

  /**
   * Sends email verification to the visitor
   * Send email verification email to a specified email address
   */
  async createEmailVerification(
    requestParameters: CreateEmailVerificationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EmailVerification> {
    const response = await this.createEmailVerificationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create a new attempt to verify the email
   */
  async createEmailVerificationAttemptRaw(
    requestParameters: CreateEmailVerificationAttemptOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EmailVerificationAttempt>> {
    if (requestParameters['emailVerificationId'] == null) {
      throw new runtime.RequiredError(
        'emailVerificationId',
        'Required parameter "emailVerificationId" was null or undefined when calling createEmailVerificationAttempt().',
      );
    }

    if (requestParameters['createEmailVerificationAttemptRequest'] == null) {
      throw new runtime.RequiredError(
        'createEmailVerificationAttemptRequest',
        'Required parameter "createEmailVerificationAttemptRequest" was null or undefined when calling createEmailVerificationAttempt().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/email-verifications/{emailVerificationId}/attempts`.replace(
          `{${'emailVerificationId'}}`,
          encodeURIComponent(String(requestParameters['emailVerificationId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateEmailVerificationAttemptRequestToJSON(requestParameters['createEmailVerificationAttemptRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EmailVerificationAttemptFromJSON(jsonValue));
  }

  /**
   * Create a new attempt to verify the email
   */
  async createEmailVerificationAttempt(
    requestParameters: CreateEmailVerificationAttemptOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EmailVerificationAttempt> {
    const response = await this.createEmailVerificationAttemptRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates new KYC inquiry and verification process for KYC inquiry which completed with status `document_rejected`
   * Creates new KYC inquiry for a particular tid
   */
  async createKycInquiryRaw(
    requestParameters: CreateKycInquiryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<KycInquiryResponse>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling createKycInquiry().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/kyc-inquiries`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => KycInquiryResponseFromJSON(jsonValue));
  }

  /**
   * Creates new KYC inquiry and verification process for KYC inquiry which completed with status `document_rejected`
   * Creates new KYC inquiry for a particular tid
   */
  async createKycInquiry(
    requestParameters: CreateKycInquiryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<KycInquiryResponse> {
    const response = await this.createKycInquiryRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates new order
   */
  async createOrderRaw(
    requestParameters: CreateOrderOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TransactionOrderResponse>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling createOrder().',
      );
    }

    if (requestParameters['createOrderRequest'] == null) {
      throw new runtime.RequiredError(
        'createOrderRequest',
        'Required parameter "createOrderRequest" was null or undefined when calling createOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/orders`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateOrderRequestToJSON(requestParameters['createOrderRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TransactionOrderResponseFromJSON(jsonValue));
  }

  /**
   * Creates new order
   */
  async createOrder(
    requestParameters: CreateOrderOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TransactionOrderResponse> {
    const response = await this.createOrderRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Sends OTP to the visitor, to verify the payment instrument recollection
   */
  async createPaymentInstrumentRecollectionOtpVerificationRaw(
    requestParameters: CreatePaymentInstrumentRecollectionOtpVerificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentInstrumentRecollectionOtpVerificationResponse>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling createPaymentInstrumentRecollectionOtpVerification().',
      );
    }

    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling createPaymentInstrumentRecollectionOtpVerification().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/payment-instrument-recollections/{id}/verifications`
          .replace(`{${'tid'}}`, encodeURIComponent(String(requestParameters['tid'])))
          .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentInstrumentRecollectionOtpVerificationResponseFromJSON(jsonValue),
    );
  }

  /**
   * Sends OTP to the visitor, to verify the payment instrument recollection
   */
  async createPaymentInstrumentRecollectionOtpVerification(
    requestParameters: CreatePaymentInstrumentRecollectionOtpVerificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentInstrumentRecollectionOtpVerificationResponse> {
    const response = await this.createPaymentInstrumentRecollectionOtpVerificationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates new SEPA payment instrument
   */
  async createSepaPaymentInstrumentRaw(
    requestParameters: CreateSepaPaymentInstrumentOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SepaPaymentInstrumentResponse>> {
    if (requestParameters['createSepaPaymentInstrumentRequest'] == null) {
      throw new runtime.RequiredError(
        'createSepaPaymentInstrumentRequest',
        'Required parameter "createSepaPaymentInstrumentRequest" was null or undefined when calling createSepaPaymentInstrument().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/v1/sepa-payment-instruments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSepaPaymentInstrumentRequestToJSON(requestParameters['createSepaPaymentInstrumentRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SepaPaymentInstrumentResponseFromJSON(jsonValue));
  }

  /**
   * Creates new SEPA payment instrument
   */
  async createSepaPaymentInstrument(
    requestParameters: CreateSepaPaymentInstrumentOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SepaPaymentInstrumentResponse> {
    const response = await this.createSepaPaymentInstrumentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets details of a specific crypto transfer
   * Gets crypto transfer
   */
  async getCryptoTransferRaw(
    requestParameters: GetCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransfer>> {
    if (requestParameters['cryptoTransferId'] == null) {
      throw new runtime.RequiredError(
        'cryptoTransferId',
        'Required parameter "cryptoTransferId" was null or undefined when calling getCryptoTransfer().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/crypto-transfers/{cryptoTransferId}`.replace(
          `{${'cryptoTransferId'}}`,
          encodeURIComponent(String(requestParameters['cryptoTransferId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferFromJSON(jsonValue));
  }

  /**
   * Gets details of a specific crypto transfer
   * Gets crypto transfer
   */
  async getCryptoTransfer(
    requestParameters: GetCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransfer> {
    const response = await this.getCryptoTransferRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets crypto transfer destination address
   */
  async getCryptoTransferDestinationAddressRaw(
    requestParameters: GetCryptoTransferDestinationAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransferDestinationAddress>> {
    if (requestParameters['cryptoTransferId'] == null) {
      throw new runtime.RequiredError(
        'cryptoTransferId',
        'Required parameter "cryptoTransferId" was null or undefined when calling getCryptoTransferDestinationAddress().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/crypto-transfers/{cryptoTransferId}/destination-crypto-address`.replace(
          `{${'cryptoTransferId'}}`,
          encodeURIComponent(String(requestParameters['cryptoTransferId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferDestinationAddressFromJSON(jsonValue));
  }

  /**
   * Gets crypto transfer destination address
   */
  async getCryptoTransferDestinationAddress(
    requestParameters: GetCryptoTransferDestinationAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransferDestinationAddress> {
    const response = await this.getCryptoTransferDestinationAddressRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * retrieves supported fiat and crypto currencies
   * Gets supported currencies
   */
  async getCurrenciesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Currencies>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/currencies`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CurrenciesFromJSON(jsonValue));
  }

  /**
   * retrieves supported fiat and crypto currencies
   * Gets supported currencies
   */
  async getCurrencies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Currencies> {
    const response = await this.getCurrenciesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Verifies wherever iban is supported and is real and retrieves bank details
   * Gets IBAN details
   */
  async getIbanRaw(
    requestParameters: GetIbanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<IbanDetails>> {
    if (requestParameters['iban'] == null) {
      throw new runtime.RequiredError(
        'iban',
        'Required parameter "iban" was null or undefined when calling getIban().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/ibans/{iban}`.replace(`{${'iban'}}`, encodeURIComponent(String(requestParameters['iban']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => IbanDetailsFromJSON(jsonValue));
  }

  /**
   * Verifies wherever iban is supported and is real and retrieves bank details
   * Gets IBAN details
   */
  async getIban(
    requestParameters: GetIbanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IbanDetails> {
    const response = await this.getIbanRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the details for payout
   */
  async getPayoutRaw(
    requestParameters: GetPayoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FiatPayout>> {
    if (requestParameters['fiatPayoutId'] == null) {
      throw new runtime.RequiredError(
        'fiatPayoutId',
        'Required parameter "fiatPayoutId" was null or undefined when calling getPayout().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/fiat-payouts/{fiatPayoutId}`.replace(
          `{${'fiatPayoutId'}}`,
          encodeURIComponent(String(requestParameters['fiatPayoutId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => FiatPayoutFromJSON(jsonValue));
  }

  /**
   * Get the details for payout
   */
  async getPayout(
    requestParameters: GetPayoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FiatPayout> {
    const response = await this.getPayoutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns sell transaction details
   */
  async getTransactionRaw(
    requestParameters: GetTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Transaction>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling getTransaction().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}`.replace(`{${'tid'}}`, encodeURIComponent(String(requestParameters['tid']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
  }

  /**
   * Returns sell transaction details
   */
  async getTransaction(
    requestParameters: GetTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Transaction> {
    const response = await this.getTransactionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns available offers
   */
  async getTransactionAvailableOffersRaw(
    requestParameters: GetTransactionAvailableOffersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TransactionOffers>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling getTransactionAvailableOffers().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xCountryCode'] != null) {
      headerParameters['X-Country-Code'] = String(requestParameters['xCountryCode']);
    }

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/offers`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TransactionOffersFromJSON(jsonValue));
  }

  /**
   * Returns available offers
   */
  async getTransactionAvailableOffers(
    requestParameters: GetTransactionAvailableOffersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TransactionOffers> {
    const response = await this.getTransactionAvailableOffersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns sell transaction order
   */
  async getTransactionOrderRaw(
    requestParameters: GetTransactionOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TransactionOrderResponse>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling getTransactionOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/order`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TransactionOrderResponseFromJSON(jsonValue));
  }

  /**
   * Returns sell transaction order
   */
  async getTransactionOrder(
    requestParameters: GetTransactionOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TransactionOrderResponse> {
    const response = await this.getTransactionOrderRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get transfer instructions for a specific transaction
   * Get transfer instructions
   */
  async getTransferInstructionsRaw(
    requestParameters: GetTransferInstructionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetTransferInstructions200Response>> {
    if (requestParameters['tid'] == null) {
      throw new runtime.RequiredError(
        'tid',
        'Required parameter "tid" was null or undefined when calling getTransferInstructions().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/transactions/{tid}/crypto-transfer-instructions`.replace(
          `{${'tid'}}`,
          encodeURIComponent(String(requestParameters['tid'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetTransferInstructions200ResponseFromJSON(jsonValue));
  }

  /**
   * Get transfer instructions for a specific transaction
   * Get transfer instructions
   */
  async getTransferInstructions(
    requestParameters: GetTransferInstructionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetTransferInstructions200Response> {
    const response = await this.getTransferInstructionsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create a transaction, and have the end-user start the checkout flow.
   */
  async initiateSellRaw(
    requestParameters: InitiateSellRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InitiateSellResponse>> {
    if (requestParameters['xTrafficProviderId'] == null) {
      throw new runtime.RequiredError(
        'xTrafficProviderId',
        'Required parameter "xTrafficProviderId" was null or undefined when calling initiateSell().',
      );
    }

    if (requestParameters['createTransactionRequest'] == null) {
      throw new runtime.RequiredError(
        'createTransactionRequest',
        'Required parameter "createTransactionRequest" was null or undefined when calling initiateSell().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xTrafficProviderId'] != null) {
      headerParameters['X-Traffic-Provider-Id'] = String(requestParameters['xTrafficProviderId']);
    }

    const response = await this.request(
      {
        path: `/v1/transactions`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateTransactionRequestToJSON(requestParameters['createTransactionRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InitiateSellResponseFromJSON(jsonValue));
  }

  /**
   * Create a transaction, and have the end-user start the checkout flow.
   */
  async initiateSell(
    requestParameters: InitiateSellRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InitiateSellResponse> {
    const response = await this.initiateSellRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Rejects crypto liquidation agreement
   * Rejects crypto liquidation agreement
   */
  async rejectAgreementRaw(
    requestParameters: RejectAgreementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoLiquidationAgreementResponse>> {
    if (requestParameters['cryptoLiquidationId'] == null) {
      throw new runtime.RequiredError(
        'cryptoLiquidationId',
        'Required parameter "cryptoLiquidationId" was null or undefined when calling rejectAgreement().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/v1/crypto-liquidations/{cryptoLiquidationId}/agreements/rejections`.replace(
          `{${'cryptoLiquidationId'}}`,
          encodeURIComponent(String(requestParameters['cryptoLiquidationId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoLiquidationAgreementResponseFromJSON(jsonValue));
  }

  /**
   * Rejects crypto liquidation agreement
   * Rejects crypto liquidation agreement
   */
  async rejectAgreement(
    requestParameters: RejectAgreementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoLiquidationAgreementResponse> {
    const response = await this.rejectAgreementRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
