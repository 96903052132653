/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TransactionPaymentInstrumentRecollectionVerificationProcess
 */
export interface TransactionPaymentInstrumentRecollectionVerificationProcess {
  /**
   *
   * @type {string}
   * @memberof TransactionPaymentInstrumentRecollectionVerificationProcess
   */
  id: string;
  /**
   * process status
   * @type {string}
   * @memberof TransactionPaymentInstrumentRecollectionVerificationProcess
   */
  status: TransactionPaymentInstrumentRecollectionVerificationProcessStatus;
}

/**
 * @export
 */
export const TransactionPaymentInstrumentRecollectionVerificationProcessStatus = {
  Initiated: 'initiated',
  Completed: 'completed',
} as const;
export type TransactionPaymentInstrumentRecollectionVerificationProcessStatus =
  (typeof TransactionPaymentInstrumentRecollectionVerificationProcessStatus)[keyof typeof TransactionPaymentInstrumentRecollectionVerificationProcessStatus];

/**
 * Check if a given object implements the TransactionPaymentInstrumentRecollectionVerificationProcess interface.
 */
export function instanceOfTransactionPaymentInstrumentRecollectionVerificationProcess(
  value: object,
): value is TransactionPaymentInstrumentRecollectionVerificationProcess {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function TransactionPaymentInstrumentRecollectionVerificationProcessFromJSON(
  json: any,
): TransactionPaymentInstrumentRecollectionVerificationProcess {
  return TransactionPaymentInstrumentRecollectionVerificationProcessFromJSONTyped(json, false);
}

export function TransactionPaymentInstrumentRecollectionVerificationProcessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionPaymentInstrumentRecollectionVerificationProcess {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
  };
}

export function TransactionPaymentInstrumentRecollectionVerificationProcessToJSON(
  json: any,
): TransactionPaymentInstrumentRecollectionVerificationProcess {
  return TransactionPaymentInstrumentRecollectionVerificationProcessToJSONTyped(json, false);
}

export function TransactionPaymentInstrumentRecollectionVerificationProcessToJSONTyped(
  value?: TransactionPaymentInstrumentRecollectionVerificationProcess | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    status: value['status'],
  };
}
