/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KycCollectionStatus } from './KycCollectionStatus';
import {
  KycCollectionStatusFromJSON,
  KycCollectionStatusFromJSONTyped,
  KycCollectionStatusToJSON,
  KycCollectionStatusToJSONTyped,
} from './KycCollectionStatus';

/**
 *
 * @export
 * @interface KycCollectionResponse
 */
export interface KycCollectionResponse {
  /**
   *
   * @type {string}
   * @memberof KycCollectionResponse
   */
  inquiryId: string;
  /**
   *
   * @type {KycCollectionStatus}
   * @memberof KycCollectionResponse
   */
  status: KycCollectionStatus;
}

/**
 * Check if a given object implements the KycCollectionResponse interface.
 */
export function instanceOfKycCollectionResponse(value: object): value is KycCollectionResponse {
  if (!('inquiryId' in value) || value['inquiryId'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function KycCollectionResponseFromJSON(json: any): KycCollectionResponse {
  return KycCollectionResponseFromJSONTyped(json, false);
}

export function KycCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycCollectionResponse {
  if (json == null) {
    return json;
  }
  return {
    inquiryId: json['inquiryId'],
    status: KycCollectionStatusFromJSON(json['status']),
  };
}

export function KycCollectionResponseToJSON(json: any): KycCollectionResponse {
  return KycCollectionResponseToJSONTyped(json, false);
}

export function KycCollectionResponseToJSONTyped(
  value?: KycCollectionResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    inquiryId: value['inquiryId'],
    status: KycCollectionStatusToJSON(value['status']),
  };
}
