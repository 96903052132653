/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Supported payment methods
 * @export
 */
export const PaymentMethod = {
  Sepa: 'SEPA',
  Card: 'CARD',
} as const;
export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];

export function instanceOfPaymentMethod(value: any): boolean {
  for (const key in PaymentMethod) {
    if (Object.prototype.hasOwnProperty.call(PaymentMethod, key)) {
      if (PaymentMethod[key as keyof typeof PaymentMethod] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
  return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
  return json as PaymentMethod;
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
  return value as any;
}

export function PaymentMethodToJSONTyped(value: any, ignoreDiscriminator: boolean): PaymentMethod {
  return value as PaymentMethod;
}
