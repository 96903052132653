import { VueQueryPlugin } from '@tanstack/vue-query';
import { createApp } from 'vue';
import './style.css';
import { HeaderName } from '@simplex/rnd.header';
import { createSimplexUI, sThemeLight } from '@simplex/simplex-ui';
import { clientKey } from './app/api/use-client';
import { createChatWidget } from './app/chat-widget/chat-widget';
import { createAppRouter, routerKey } from './app/navigation/router';
import { routes } from './app/navigation/routes';
import { translationConfig } from './app/translations/config';
import App from './App.vue';
import { Configuration, TransactionApi } from './client';

const app = createApp(App);
app.use(translationConfig());
app.use(VueQueryPlugin);

const appRouter = createAppRouter(routes);
app.use(appRouter.router);
app.use(createSimplexUI({ theme: sThemeLight }));
app.provide(
  clientKey,
  new TransactionApi(
    new Configuration({
      basePath: '/api',
      headers: window.__user_country__ ? { [HeaderName.COUNTRY_CODE]: window.__user_country__ } : {},
    }),
  ),
);
app.provide(routerKey, appRouter);

const chatWidget = createChatWidget({
  key: import.meta.env.VITE_ZENDESK_KEY,
  initialQueue: [
    {
      command: 'updateTheme' as const,
      primaryColor: sThemeLight['brand-1-100'],
      textColor: sThemeLight['primary-button-text'],
    },
  ],
});
app.use(chatWidget);

app.mount('#app');
